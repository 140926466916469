import ApiService from "@/core/services/api.service";
import {PedagogicalDiaryService} from "@/core/services/apis.service";

/**
 * GET request to fetch all data
 * @returns {*}
 */
export const getAllStages = () => {
    return ApiService.get("stages");
};

/**
 * GET request to fetch a specific data
 * @param id
 * @returns {*}
 */
export const getStage = (id) => {
    return ApiService.get("stages", id);
};

/**
 * POST request to create a new stage
 * @param payload
 * @returns {*}
 */
export const createStage = (payload) => {
    return ApiService.post("stages", payload);
};

/**
 * PUT request to update a specific stage
 * @param id
 * @param payload
 * @returns {*}
 */
export const updateStage = (id, payload) => {
    return ApiService.update("stages", id, payload);
};

/**
 * DELETE request to delete the specified stage
 * @param id
 * @returns {*}
 */
export const deleteStage = (id) => {
    return ApiService.delete(`stages/${id}`);
};

/**
 * GET request to change status of the specified stage
 * @param id
 * @returns {*}
 */
export const changeStatusStage = (id) => {
    return ApiService.get("stages/status", id);
};

/**
 * GET request to change status of the specified stage
 * @param grade_id
 * @param speciality_id
 * @param headers
 * @returns {*}
 */
export const stageByAcademicLevel = (grade_id, speciality_id = 8, headers) => {
    return ApiService.get(
        `stages/grade/${grade_id}/speciality/${speciality_id}`,
        "",
        headers
    );
};

/**
 * GET request to get stages of given subject
 * @param subjectId
 * @returns {*}
 */
export const getBySubjectId = (subjectId) => {
    return ApiService.get(`stages/subject/${subjectId}`);
};

// ------------------ DP api endpoints ----------------

/**
 * GET request to get stages of given subject
 * @param gradeId
 * @returns {*}
 */
export const getYearStartAndEndDate = (gradeId) => {
    return PedagogicalDiaryService.get(`dashboard/year-start-end/grade/${gradeId}`);
};

export default {
    stageByAcademicLevel,
    getAllStages,
    getStage,
    createStage,
    updateStage,
    deleteStage,
    changeStatusStage,
    getBySubjectId,
    getYearStartAndEndDate,
};
