import ApiService from "@/core/services/api.service";

/**
 * GET request to fetch all data
 * @returns {*}
 */
export const getAllSubjects = () => {
  return ApiService.get("subjects");
};

/**
 * GET request to fetch a specific data
 * @param id
 * @returns {*}
 */
export const getSubject = (id) => {
  return ApiService.get("subjects", id);
};

/**
 * POST request to create a new subject
 * @param payload
 * @returns {*}
 */
export const createSubject = (payload) => {
  return ApiService.post("subjects", payload);
};

/**
 * PUT request to update a specific subject
 * @param id
 * @param payload
 * @returns {*}
 */
export const updateSubject = (id, payload) => {
  return ApiService.update("subjects", id, payload);
};

/**
 * DELETE request to delete the specified subject
 * @param id
 * @returns {*}
 */
export const deleteSubject = (id) => {
  return ApiService.delete(`subjects/${id}`);
};

/**
 * GET request to change status of the specified subject
 * @param id
 * @returns {*}
 */
export const changeStatusSubject = (id) => {
  return ApiService.get("subjects/status", id);
};

/**
 * GET request to fetch a subjects by grade_speciality_group_id
 * @returns {*}
 */
export const getSubjectsByTechnicalGroup = (grade_speciality_group_id) => {
  return ApiService.get(
    `technical_groups/${grade_speciality_group_id}/subjects`
  );
};

/**
 * GET request to fetch a subjects by grade_speciality_group_id and grade_section_group
 * @returns {*}
 */
export const getSubjectsByTechnicalGroupAndAcademicGroup = (
  grade_speciality_group_id,
  grade_section_group_id
) => {
  return ApiService.get(
    `technical_groups/${grade_speciality_group_id}/academic_groups/${grade_section_group_id}/subjects`
  );
};

export default {
  getAllSubjects,
  getSubject,
  createSubject,
  updateSubject,
  deleteSubject,
  changeStatusSubject,
  getSubjectsByTechnicalGroup,
  getSubjectsByTechnicalGroupAndAcademicGroup,
};
