import ApiService from "@/core/services/api.service";

let baseURL;

if (process.env.REPORTS_APP_ENV === "production") {
  baseURL = process.env.REPORTS_API_URL;
} else if (process.env.REPORTS_APP_ENV === "development") {
  baseURL = process.env.REPORTS_DEV_API_URL;
} else {
  baseURL = process.env.REPORTS_LOCAL_API_URL;
}

/**
 * GET request to fetch a academic load of one subject
 * @returns {*}
 */
export const getSubjectTeachersBySubject = (id) => {
  return ApiService.get("subject_teachers/subject", id);
};

/**
 * GET request to fetch subjects of teacher
 * @returns {*}
 */
export const getSubjectsOfTeacher = (id) => {
  return ApiService.get("subject_teachers/teacher", id);
};

/**
 * GET request to fetch subjects of teacher with groups
 * @returns {*}
 */
export const getSubjectTeacherGroup = (id) => {
  return ApiService.get("subject_teachers/teacher-with-groups", id);
};

/**
 * POST request to create a new subject_teachers
 * @param payload
 * @returns {*}
 */
export const createSubjectTeacher = (payload) => {
  return ApiService.post("subject_teachers", payload);
};

/**
 * PUT request to update a specific subject_teachers
 * @param id
 * @param payload
 * @returns {*}
 */
export const updateSubjectTeacher = (id, payload) => {
  return ApiService.update("subject_teachers", id, payload);
};

/**
 * GET request to fetch a specific data
 * @param id
 * @returns {*}
 */
export const getSubjectTeacherWithGroups = (id) => {
  return ApiService.get("subject_teachers", id);
};

/**
 * GET request to fetch a specific data
 * @param id
 * @returns {*}
 */
export const getStageAverageScoreTecnical = (
  grade_speciality_group_id,
  stage_id,
  subject_id
) => {
  return ApiService.get(
    `${baseURL}/scores/grade-speciality-group/${grade_speciality_group_id}/stage/${stage_id}/subject/${subject_id}/no-recovery`
  );
};

/**
 * GET request to fetch a specific data
 * @param id
 * @returns {*}
 */
export const getStageAverageScoreAcademic = (
  grade_section_group_id,
  stage_id,
  subject_id
) => {
  return ApiService.get(
    `${baseURL}/scores/grade-section-group/${grade_section_group_id}/stage/${stage_id}/subject/${subject_id}/no-recovery`
  );
};

export default {
  getSubjectTeachersBySubject,
  getSubjectTeacherWithGroups,
  createSubjectTeacher,
  updateSubjectTeacher,
  getSubjectsOfTeacher,
  getStageAverageScoreTecnical,
  getStageAverageScoreAcademic,
  getSubjectTeacherGroup,
};
