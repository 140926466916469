var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-btn',{staticClass:"mb-2",attrs:{"text":""},on:{"click":_vm.goBack}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-arrow-left ")]),_vm._v(" Regresar a reportes ")],1),_c('div',{staticClass:"card card-custom gutter-b"},[_vm._m(0),_c('div',{staticClass:"card-body px-1 px-sm-5 pt-0"},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"elevation-2 mb-5"},[_c('p',{staticClass:"text-h5 ma-0 pt-4 pl-4 font-weight-medium"},[_vm._v(" Parámetros ")]),_c('v-divider'),_c('v-container',{staticClass:"pa-4"},[_c('v-row',[_c('v-col',[_c('p',{staticClass:"ma-0 pa-0 text-body-1"},[_c('strong',[_vm._v(" Debes seleccionar una asignatura o módulo, luego debes seleccionar uno de los docentes que imparte esa asignatura o módulo, por último la etapa de la que desea ver el registro de las calificaciones. ")])])])],1),_c('v-row',[_c('v-col',[_c('v-autocomplete',{attrs:{"label":_vm.subjects.areLoading
                          ? 'Cargando asignaturas'
                          : 'Asignaturas',"loading":_vm.subjects.areLoading,"disabled":!_vm.subjects.data.length,"outlined":"","dense":"","hide-details":"","clearable":"","items":_vm.subjects.data,"item-text":"name","item-value":"id"},scopedSlots:_vm._u([{key:"selection",fn:function({ item, index }){return [(index === 0)?_c('v-chip',{attrs:{"color":"primary","small":""}},[_c('span',[_vm._v(_vm._s(`${item.name} | ${item.grade}, ${item.speciality}`))])]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" (+"+_vm._s(_vm.report.params.subject_id.length - 1)+" seleccionados) ")]):_vm._e()]}},{key:"item",fn:function(data){return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(data.item.name)}}),_c('v-list-item-subtitle',{staticClass:"mt-1"},[_c('span',[_vm._v(" Grado "),_c('strong',{staticClass:"ml-1"},[_vm._v(_vm._s(data.item.grade))])]),_c('span',{staticClass:"ml-2"},[_vm._v(" Especialidad "),_c('strong',{staticClass:"ml-1"},[_vm._v(_vm._s(data.item.speciality))])])])],1)]}}]),model:{value:(_vm.report.params.subject_id),callback:function ($$v) {_vm.$set(_vm.report.params, "subject_id", $$v)},expression:"report.params.subject_id"}})],1)],1),_c('v-row',{staticClass:"align-items-center mb-5"},[_c('v-col',{staticClass:"my-2 my-md-0",attrs:{"cols":"12","sm":"6"}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('v-select',{attrs:{"label":_vm.subjectTeachers.areLoading
                            ? 'Cargando docentes e instructores..'
                            : 'Docentes e instructores',"loading":_vm.subjectTeachers.areLoading,"disabled":_vm.subjectTeachers.areLoading ||
                            !_vm.subjectTeachers.data.length ||
                            !_vm.report.params.subject_id,"outlined":"","hide-details":"","items":_vm.subjectTeachers.data,"item-text":"teacher_full_name","item-value":"id"},scopedSlots:_vm._u([{key:"selection",fn:function({ item, index }){return [(index === 0)?_c('v-chip',{attrs:{"color":"primary","small":""}},[_c('span',[_vm._v(_vm._s(item.teacher_full_name))])]):_vm._e()]}}]),model:{value:(_vm.report.params.subject_teacher_id),callback:function ($$v) {_vm.$set(_vm.report.params, "subject_teacher_id", $$v)},expression:"report.params.subject_teacher_id"}})],1)]),_c('v-col',{staticClass:"my-2 my-md-0",attrs:{"cols":"12","sm":"6"}},[_c('v-select',{attrs:{"label":_vm.stages.areLoading ? 'Cargando etapas..' : 'Etapa',"loading":_vm.stages.areLoading,"disabled":_vm.stages.areLoading ||
                          !_vm.stages.data.length ||
                          !_vm.report.params.subject_id,"outlined":"","hide-details":"","clearable":"","items":_vm.stages.data,"item-text":"stage.name","item-value":"stage_id"},scopedSlots:_vm._u([{key:"selection",fn:function({ item, index }){return [(index === 0)?_c('v-chip',{attrs:{"color":"primary","small":""}},[_c('span',[_vm._v(_vm._s(item.stage.name))])]):_vm._e()]}}]),model:{value:(_vm.report.params.stage_id),callback:function ($$v) {_vm.$set(_vm.report.params, "stage_id", $$v)},expression:"report.params.stage_id"}})],1)],1)],1)],1),[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"10"}},[_c('v-card',{attrs:{"outlined":"","disabled":!_vm.canGenerateTeacherEnteredScoresReport}},[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_c('div',{staticClass:"text-overline mb-4"},[_vm._v(" Docentes ")]),_c('v-list-item-title',{staticClass:"text-h5 mb-1"},[_vm._v(" Reporte de calificaciones ingresadas por un docente ")]),_c('v-list-item-subtitle',[_vm._v(" Reporte de calificaciones ingresadas por el docente o instructor seleccionado en la etapa seleccionada. ")])],1),_c('v-list-item-avatar',{staticClass:"rounded",attrs:{"tile":"","icon":"","size":"80","color":"success"}},[_c('v-icon',{staticClass:"ml-n1",attrs:{"x-large":"","color":"white"}},[_vm._v("mdi-microsoft-excel")])],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"outlined":"","rounded":"","text":"","href":_vm.getTeacherEnteredScoresReportUrl,"download":""}},[_vm._v(" Generar reporte ")])],1)],1)],1)],1)]],2)],1)],1)],1)])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header flex-nowrap border-0 pt-6 pb-0"},[_c('div',{staticClass:"card-title"},[_c('div',{staticClass:"card-label"},[_c('h3',[_vm._v(" Reporte de calificaciones ingresadas por un docente ")]),_c('div',{staticClass:"text-muted text-body-1"},[_vm._v(" Generación de reporte de calificaciones ingresadas por un docente por etapa ")])])])])
}]

export { render, staticRenderFns }