<template>
  <div>
    <v-btn text class="mb-2" @click="goBack">
      <v-icon left>
        mdi-arrow-left
      </v-icon>
      Regresar a reportes
    </v-btn>
    <div class="card card-custom gutter-b">
      <!-- begin::Page header and title -->
      <div class="card-header flex-nowrap border-0 pt-6 pb-0">
        <div class="card-title">
          <div class="card-label">
            <h3>
              Reporte de calificaciones ingresadas por un docente
            </h3>
            <div class="text-muted text-body-1">
              Generación de reporte de calificaciones ingresadas por un docente
              por etapa
            </div>
          </div>
        </div>
      </div>
      <!-- end::Page header and title -->
      <div class="card-body px-1 px-sm-5 pt-0">
        <v-container>
          <v-row>
            <v-col cols="12">
              <!-- begin::filters for report generation-->
              <v-card class="elevation-2 mb-5">
                <p class="text-h5 ma-0 pt-4 pl-4 font-weight-medium">
                  Parámetros
                </p>
                <v-divider></v-divider>
                <!-- begin::Filters combos -->
                <v-container class="pa-4">
                  <v-row
                    ><v-col>
                      <p class="ma-0 pa-0 text-body-1">
                        <strong>
                          Debes seleccionar una asignatura o módulo, luego debes
                          seleccionar uno de los docentes que imparte esa
                          asignatura o módulo, por último la etapa de la que
                          desea ver el registro de las calificaciones.
                        </strong>
                      </p>
                    </v-col></v-row
                  >
                  <v-row>
                    <v-col>
                      <v-autocomplete
                        :label="
                          subjects.areLoading
                            ? 'Cargando asignaturas'
                            : 'Asignaturas'
                        "
                        :loading="subjects.areLoading"
                        :disabled="!subjects.data.length"
                        outlined
                        dense
                        hide-details
                        clearable
                        :items="subjects.data"
                        item-text="name"
                        item-value="id"
                        v-model="report.params.subject_id"
                      >
                        <template v-slot:selection="{ item, index }">
                          <v-chip color="primary" small v-if="index === 0">
                            <span>{{
                              `${item.name} | ${item.grade}, ${item.speciality}`
                            }}</span>
                          </v-chip>
                          <span
                            v-if="index === 1"
                            class="grey--text text-caption"
                          >
                            (+{{ report.params.subject_id.length - 1 }}
                            seleccionados)
                          </span>
                        </template>
                        <template v-slot:item="data">
                          <v-list-item-content>
                            <v-list-item-title
                              v-html="data.item.name"
                            ></v-list-item-title>
                            <v-list-item-subtitle class="mt-1">
                              <span>
                                Grado
                                <strong class="ml-1">{{
                                  data.item.grade
                                }}</strong>
                              </span>
                              <span class="ml-2">
                                Especialidad
                                <strong class="ml-1">{{
                                  data.item.speciality
                                }}</strong>
                              </span>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </template>
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row class="align-items-center mb-5">
                    <!-- begin::select academic level filter-->
                    <v-col cols="12" sm="6" class="my-2 my-md-0">
                      <div class="d-flex align-items-center">
                        <!-- begin:teacher selection combo -->
                        <v-select
                          :label="
                            subjectTeachers.areLoading
                              ? 'Cargando docentes e instructores..'
                              : 'Docentes e instructores'
                          "
                          :loading="subjectTeachers.areLoading"
                          :disabled="
                            subjectTeachers.areLoading ||
                              !subjectTeachers.data.length ||
                              !report.params.subject_id
                          "
                          outlined
                          hide-details
                          :items="subjectTeachers.data"
                          item-text="teacher_full_name"
                          item-value="id"
                          v-model="report.params.subject_teacher_id"
                        >
                          <!-- begin::selected teacher selection item -->
                          <template v-slot:selection="{ item, index }">
                            <v-chip color="primary" small v-if="index === 0">
                              <span>{{ item.teacher_full_name }}</span>
                            </v-chip>
                          </template>
                          <!-- end::selected teacher selection item -->
                        </v-select>
                        <!-- end:teacher selection combo -->
                      </div>
                    </v-col>
                    <!-- end::select academic level filter-->

                    <!-- begin::select stage filter -->
                    <v-col cols="12" sm="6" class="my-2 my-md-0">
                      <v-select
                        :label="
                          stages.areLoading ? 'Cargando etapas..' : 'Etapa'
                        "
                        :loading="stages.areLoading"
                        :disabled="
                          stages.areLoading ||
                            !stages.data.length ||
                            !report.params.subject_id
                        "
                        outlined
                        hide-details
                        clearable
                        :items="stages.data"
                        item-text="stage.name"
                        item-value="stage_id"
                        v-model="report.params.stage_id"
                      >
                        <!-- begin::selected stage item -->
                        <template v-slot:selection="{ item, index }">
                          <v-chip color="primary" small v-if="index === 0">
                            <span>{{ item.stage.name }}</span>
                          </v-chip>
                        </template>
                        <!-- end::selected stage item -->
                      </v-select>
                    </v-col>
                    <!-- end::select stage filter-->
                  </v-row>
                </v-container>
                <!-- end::Filters combos -->
              </v-card>
              <!-- end::filters for report generation-->

              <!-- begin::cards with generator button for each report -->
              <template>
                <v-row>
                  <!-- begin::excel with entered scores col-container-->
                  <v-col cols="12" sm="10">
                    <!-- begin::excel with entered scores-->
                    <v-card
                      outlined
                      :disabled="!canGenerateTeacherEnteredScoresReport"
                    >
                      <!-- begin::card body -->
                      <v-list-item three-line>
                        <v-list-item-content>
                          <div class="text-overline mb-4">
                            Docentes
                          </div>
                          <v-list-item-title class="text-h5 mb-1">
                            Reporte de calificaciones ingresadas por un docente
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            Reporte de calificaciones ingresadas por el docente
                            o instructor seleccionado en la etapa seleccionada.
                          </v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-avatar
                          class="rounded"
                          tile
                          icon
                          size="80"
                          color="success"
                        >
                          <v-icon x-large color="white" class="ml-n1"
                            >mdi-microsoft-excel</v-icon
                          ></v-list-item-avatar
                        >
                      </v-list-item>
                      <!-- end::card body -->

                      <!-- begin:: card button for report generation -->
                      <v-card-actions>
                        <v-btn
                          outlined
                          rounded
                          text
                          :href="getTeacherEnteredScoresReportUrl"
                          download
                        >
                          Generar reporte
                        </v-btn>
                      </v-card-actions>
                      <!-- end:: card button for report generation -->
                    </v-card>
                    <!-- end::excel with entered scores-->

                    <!-- begin::fallback card if not all necesary parameters have been selected -->

                    <!-- end::fallback card if not all necesary parameters have been selected -->
                  </v-col>
                  <!-- end::excel with entered scores col-container-->
                </v-row>
              </template>
              <!-- end::cards with generator button for each report -->
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
  </div>
</template>

<script>
import {
  GET_CURRENT_PAGE_ACTIONS,
  PURGE_CURRENT_PAGE_ACTIONS,
} from "@/core/services/store/role.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
import subjectTeacherRepository from "@/repositories/subjectTeacherRepository";
import stageRepository from "@/repositories/stageRepository";
import subjectRepository from "@/repositories/subjectRepository";

export default {
  name: "ReportCard",
  title: "Reporte de calificaciones ingresadas por un docente | GE ITR",
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Reportes", route: "reports" },
      {
        title: "Calificaciones ingresadas por un docente",
        route: "report_card",
      },
    ]);
    this.$store.dispatch(
      GET_CURRENT_PAGE_ACTIONS,
      "Reporte de calificaciones ingresadas por un docente"
    );
    this.loadSubjects();
  },
  data() {
    return {
      TERCER_CICLO_ID: 1,
      BACHILLERATO_ID: 2,
      report: {
        params: {},
      },
      subjects: {
        data: [],
        filters: {},
        areLoading: false,
      },
      stages: {
        data: [],
        filters: {},
        areLoading: false,
      },
      subjectTeachers: {
        data: [],
        filters: {},
        areLoading: false,
      },
    };
  },
  methods: {
    loadSubjects() {
      this.subjects.areLoading = true;
      subjectRepository
        .getAllSubjects()
        .then(({ data }) => {
          this.subjects.data = data;
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        })
        .finally(() => {
          this.subjects.areLoading = false;
        });
    },

    //Fetching all yearly stages
    loadStages() {
      this.stages.areLoading = true;
      stageRepository
        .stageByAcademicLevel(this.gradeId, this.specialityId)
        .then(({ data }) => {
          this.stages.data = data;
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "Hubo un error al intentar obtener las etapas",
          });
        })
        .finally(() => {
          this.stages.areLoading = false;
        });
    },

    getSubjectTeachersOfSubject(subjectId) {
      this.subjectTeachers.areLoading = true;
      subjectTeacherRepository
        .getSubjectTeachersBySubject(subjectId)
        .then(({ data }) => {
          this.subjectTeachers.data = data;
        })
        .catch((err) => {
          console.error(err);
          // Sweetalert that indicates the operation cannot be completed
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        })
        .finally(() => {
          this.subjectTeachers.areLoading = false;
        });
    },

    resetReportParams() {
      this.report.params = {};
    },

    goBack() {
      this.$router.go(-1);
    },
  },
  computed: {
    ...mapGetters(["currentPageActions"]),
    reportsApiUrl() {
      let baseURL;
      if (process.env.APP_ENV === "production") {
        baseURL = process.env.REPORTS_API_URL;
      } else if (process.env.APP_ENV === "development") {
        baseURL = process.env.REPORTS_DEV_API_URL;
      } else {
        baseURL = process.env.REPORTS_LOCAL_API_URL;
      }
      return baseURL;
    },
    getTeacherEnteredScoresReportUrl() {
      if (!this.canGenerateTeacherEnteredScoresReport) {
        return;
      }
      const { params } = this.report;

      let url = `${this.reportsApiUrl}/scores/subject-teacher/${params.subject_teacher_id}/stage/${params.stage_id}`;
      return url;
    },
    canGenerateTeacherEnteredScoresReport() {
      return (
        this.teacherHasBeenSelected &&
        this.stageHasBeenSelected &&
        this.subjectHasBeenSelected
      );
    },

    teacherHasBeenSelected() {
      return !!this.report.params.subject_teacher_id;
    },
    stageHasBeenSelected() {
      return !!this.report.params.stage_id;
    },
    subjectHasBeenSelected() {
      return !!this.report.params.subject_id;
    },

    subjectId() {
      return this.report.params.subject_id;
    },

    gradeId() {
      if (!("subject_id" in this.report.params)) {
        return;
      }

      const subject = this.subjects.data.find(
        (item) => item.id == this.report.params.subject_id
      );
      if (subject) {
        return subject.grade_id;
      }
    },

    specialityId() {
      if (!("subject_id" in this.report.params)) {
        return;
      }

      const subject = this.subjects.data.find(
        (item) => item.id == this.report.params.subject_id
      );
      if (subject) {
        return subject.speciality_id;
      }
    },
    filteredSubjects() {
      return this.filterData(this.report.params, this.subjects.data);
    },
  },
  beforeDestroy() {
    this.$store.commit(PURGE_CURRENT_PAGE_ACTIONS);
  },
  watch: {
    subjectId(newValue) {
      if (newValue) {
        this.getSubjectTeachersOfSubject(newValue);
        this.loadStages();
      }
    },
  },
};
</script>

<style scoped>
.red-pdf {
  background-color: #d93025 !important;
}
</style>
