import { render, staticRenderFns } from "./teacher_entered_scores.vue?vue&type=template&id=9f85126c&scoped=true"
import script from "./teacher_entered_scores.vue?vue&type=script&lang=js"
export * from "./teacher_entered_scores.vue?vue&type=script&lang=js"
import style0 from "./teacher_entered_scores.vue?vue&type=style&index=0&id=9f85126c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9f85126c",
  null
  
)

export default component.exports